import { AccountBox, Code, East } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

const services = [
    {
        id: 0,
        title: 'Cuentas',
        description: 'Accede a todos nuestros productos y maneja tu información con una sola cuenta.',
        image: "https://firebasestorage.googleapis.com/v0/b/yuga-core.appspot.com/o/branch%2Fyugashiki-owns%2Fcuentas-banner.png?alt=media&token=067996bb-9800-4a46-8408-cb6a9c79e9ea"
    },
    {
        id: 1,
        title: "Desarrollo Web",
        description: "Toda empresa ya sea grande, mediana o pequeña necesita y debe tener un sitio web ya que brinda una imagen profesional de la empresa, ventajas competitivas y valores agregados para el cliente.",
        image: "https://firebasestorage.googleapis.com/v0/b/yuga-core.appspot.com/o/branch%2Fyugashiki-owns%2Fweb-dev-banner.png?alt=media&token=488820a2-e3ca-4093-b4b2-437a105743fe"
    }
]

export default function SecondView() {
    return (
        <div className='home-section second'>
            <div className="body">
                <h2>¿Qué te ofrecemos?</h2>
                <div className="content">
                    {services.map((service) => (
                        <div key={service.id} className="service-card">
                            <div className="service-layout">
                                <div className="service-image">
                                    <img 
                                        src={service.image}
                                        alt={`${service.title} - ${service.description}`}
                                    />
                                </div>
                                <Button className='btn variant-black radius-full'>
                                    <span className='title'>{service.title}</span>
                                    <East />
                                </Button>
                                {/* <div className="service-body">
                                    <div className="text">
                                        <h3>{service.title}</h3>
                                        <p>{service.description}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      )
}

import React from 'react';
import './Footer.scss';
import { Button } from '@mui/material';
import { FacebookRounded, Instagram, LinkedIn, X, YouTube } from '@mui/icons-material';

export default function Footer() {
    return (
        <div className='footer'>
            <footer>
                <div className="body">
                    <div className="section">
                        <div className="logo font-quicksand">
                            <span>Yügashiki</span>
                        </div>
                        <div className="socials">
                            <Button className='btn icon-btn'>
                                <X />
                            </Button>
                            <Button className='btn icon-btn'>
                                <Instagram />
                            </Button>
                            <Button className='btn icon-btn'>
                                <FacebookRounded />
                            </Button>
                            <Button className='btn icon-btn'>
                                <YouTube />
                            </Button>
                            <Button className='btn icon-btn'>
                                <LinkedIn />
                            </Button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

import { AuthProvider } from "./context/AuthContext";

function App({ children }) {
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  );
}

export default App;

import { createBrowserRouter } from "react-router-dom";
import Layout from "../page/Layout";
import Home from "../page";
import Login from "../page/auth/Login";
import Register from "../page/auth/Register";
import Products from "../page/products/Products";

export const YugaRouter = createBrowserRouter([
    {
        path: "/api/*",
        element: null
    },
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "/products",
                element: <Products />
            },
            {
                path: "auth",
                children: [
                    {
                        path: "login",
                        element: <Login />
                    },
                    {
                        path: "register",
                        element: <Register />
                    }
                ]
            }
        ]
    },
])
import React from 'react'

export default function Moon({ className }) {
    return (
        <svg className={className} viewBox="0 0 112 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M85.3914 57.4262C80.2417 75.3854 61.5768 85.8077 43.6101 80.8146C48.0033 78.3216 51.461 74.1728 52.9633 68.9336C56.0079 58.3158 49.8686 47.2402 39.2508 44.1956C31.6015 42.0022 23.7147 44.5753 18.7642 50.1556C18.531 46.3885 18.9258 42.5181 20.0256 38.6829C25.2014 20.6326 44.0299 10.1958 62.0802 15.3716C80.1304 20.5475 90.5672 39.3759 85.3914 57.4262Z" fill="white"/>
            <path d="M103.661 15.8386C104.258 18.045 108.844 20.1193 108.844 20.1193C108.844 20.1193 104.131 18.979 101.883 20.2976C99.6353 21.6161 97.8564 24.8442 97.8564 24.8442C97.8564 24.8442 98.9567 22.0839 97.6366 18.6049C96.3166 15.1259 93.1316 13.8564 93.1316 13.8564C93.1316 13.8564 96.5289 15.2105 99.414 14.1459C102.299 13.0812 104.119 9.13149 104.119 9.13149C104.119 9.13149 103.064 13.6321 103.661 15.8386Z" stroke="white" stroke-linejoin="round"/>
            <path d="M96.5293 90.5204C98.2772 91.9934 103.228 91.0842 103.228 91.0842C103.228 91.0842 98.7057 92.8328 97.6098 95.1974C96.5139 97.562 96.8916 101.228 96.8916 101.228C96.8916 101.228 96.2228 98.333 93.1555 96.2264C90.0883 94.1198 86.7475 94.8915 86.7475 94.8915C86.7475 94.8915 90.311 94.0683 92.0751 91.5494C93.8391 89.0305 93.0843 84.7474 93.0843 84.7474C93.0843 84.7474 94.7815 89.0474 96.5293 90.5204Z" stroke="white" stroke-linejoin="round"/>
            <path d="M13.6605 9.83856C14.2574 12.045 18.8442 14.1193 18.8442 14.1193C18.8442 14.1193 14.1312 12.979 11.8832 14.2976C9.63513 15.6161 7.8563 18.8442 7.8563 18.8442C7.8563 18.8442 8.95656 16.0839 7.63651 12.6049C6.31645 9.12588 3.13144 7.85635 3.13144 7.85635C3.13144 7.85635 6.52879 9.21051 9.41384 8.14586C12.2989 7.0812 14.1193 3.13149 14.1193 3.13149C14.1193 3.13149 13.0636 7.63208 13.6605 9.83856Z" stroke="white" stroke-linejoin="round"/>
        </svg>    
    )
}

import React from 'react'
import PulsingLight from '../PulsingLight'
import { Button } from '@mui/material'
import { East, ExpandMore } from '@mui/icons-material'
import Moon from '../../../assets/images/svg/Moon'
import { Link } from 'react-router-dom'
import { Novatrix } from 'uvcanvas'

export default function FirstView() {
  return (
    <div className='home-section first'>
      <div className="body">
        <h1>
          <span className='small'>Piensa en sitios increibles...</span>
          <span className='principal'>
            Nosotros los hacemos
            <Moon className="title-moon" />  
          </span>
        </h1>
        <div className="buttons">
          <Button 
            className='btn radius-partial variant-white'
            LinkComponent={Link}
            to="/products"
          >
            <span>Ver todos nuestros productos</span>
          </Button>
          <Button 
            className='btn radius-partial variant-black-222'
            LinkComponent={Link}
            to="/products"  
          >
            <span className='title'>Empezar</span>
            <East />
          </Button>
        </div>
      </div>
      <div className="background">
        <div className="sun"></div>
        <div className="deco">
          <div className="cube"></div>
        </div>
      </div>
    </div>
  )
}

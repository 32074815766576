import React from 'react';
import './Products.scss';

export default function Products() {
    return (
        <div className='products-page'>
            <div className="products-banner">
                <h1 aria-label='Productos de Yügashiki'>Productos de <br/> Yügashiki</h1>
            </div>
            <div className="products-list">
            </div>
        </div>
    )
}

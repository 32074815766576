import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { animated, useInView, useSpring } from 'react-spring';
import '../components/spring/home/ViewStyles.scss';
import FirstView from '../components/spring/home/FirstView';
import SecondView from '../components/spring/home/SecondView';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper/modules';
import 'swiper/css';
import { Button } from '@mui/material';
import PulsingLight from '../components/spring/PulsingLight';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Footer from '../components/footer/Footer';

const Homepage = [
  {
    id: 0,
    el: <FirstView />
  },
  {
    id: 1,
    el: <SecondView />
  },
  {
    id: 99,
    el: <div style={{ paddingTop: '13.5rem', height: '100%'}}><Footer /></div>
  }
]

export default function Home() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      delay: 1000
    }),
    {
      rootMargin: '0% 0%',
    }
  );
  // const fade = useSpring({ minHeight: '50vh', opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' });

  return (
    <>
      <Swiper
        direction='vertical'
        slidesPerView={1}
        mousewheel={true}
        className='home-swiper'
        modules={[Mousewheel, Navigation]}
        navigation={{
          nextEl: '.scroll-down',
          prevEl: '.scroll-up',
          disabledClass: 'disabled',
          clickable: true
        }}
      >
        {Homepage.map((page) => (
          <SwiperSlide key={page.id} className='home-slide'>
            {page.el}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='button-swiper scroll-up'>
        <Button className='btn icon-btn radius-full'>
          <ExpandLess />
        </Button>
      </div>
      <div className='button-swiper scroll-down'>
        <Button className='btn icon-btn radius-full'>
          <ExpandMore />
        </Button>
      </div>
      {/* <animated.div ref={ref} style={{...springs, minHeight: '100vh'}}>
        <div>Contenido animado</div>
      </animated.div> */}
    </>
  );
}

import React from 'react'

export default function Logo() {
    return (
        <svg className='svg' viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M142.68 59.44C144.76 59.44 146.44 60.16 147.72 61.6C149.16 63.04 149.88 64.72 149.88 66.64V134.32C149.88 150.16 145.4 162.24 136.44 170.56C127.48 178.88 115.4 183.04 100.2 183.04C85.16 183.04 73.16 178.88 64.2 170.56C55.4 162.24 51 150.16 51 134.32V66.64C51 64.72 51.64 63.04 52.92 61.6C54.36 60.16 56.12 59.44 58.2 59.44C60.28 59.44 61.96 60.16 63.24 61.6C64.68 63.04 65.4 64.72 65.4 66.64V134.32C65.4 145.84 68.44 154.64 74.52 160.72C80.76 166.64 89.32 169.6 100.2 169.6C111.24 169.6 119.88 166.64 126.12 160.72C132.36 154.64 135.48 145.84 135.48 134.32V100.48V66.64C135.48 64.72 136.12 63.04 137.4 61.6C138.68 60.16 140.44 59.44 142.68 59.44ZM121.08 37.6C117.88 37.6 115.4 36.8 113.64 35.2C112.04 33.44 111.24 31.04 111.24 28V25.6C111.24 22.56 112.12 20.24 113.88 18.64C115.64 16.88 118.12 16 121.32 16C124.36 16 126.68 16.88 128.28 18.64C130.04 20.24 130.92 22.56 130.92 25.6V28C130.92 31.2 130.04 33.6 128.28 35.2C126.52 36.8 124.12 37.6 121.08 37.6ZM79.8 37.6C76.6 37.6 74.12 36.8 72.36 35.2C70.76 33.44 69.96 31.04 69.96 28V25.6C69.96 22.56 70.84 20.24 72.6 18.64C74.36 16.88 76.76 16 79.8 16C83 16 85.4 16.88 87 18.64C88.76 20.24 89.64 22.56 89.64 25.6V28C89.64 31.2 88.76 33.6 87 35.2C85.24 36.8 82.84 37.6 79.8 37.6Z" fill="white"/>
        </svg>
        
    )
}

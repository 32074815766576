import { onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth } from "../services/firebase/firebase";
import Cookies from "js-cookie";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ account, setAccount ] = useState(undefined);

    useEffect(() => {
        onAuthStateChanged(auth, (firebaseUser) => {
            if (firebaseUser) {
                setAccount(firebaseUser);
            } else {
                setAccount(null);
            }
        })
    })

    const handleLogin = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);

        } catch (error) {
            console.log('error al iniciar sesión:', error)
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.log('error al cerrar sesión', error)
        }
    }

    console.log(account)

    const value = {
        account,
        login: handleLogin,
        logout: handleSignOut,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
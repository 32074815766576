import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Avatar, Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import './Header.scss';
import Logo from '../../../assets/images/svg/Logo';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import Apps from '../../../assets/images/svg/Apps';

export default function Header() {
    const [ yugashiki, setYugashiki ] = useState(false);
    const { account } = useContext(AuthContext);

    return (
        <header className="header">
            <div className='yugashiki-resources'>
                <Button className='btn font-quicksand radius-full yugashiki-resources'>
                    <span>Yügashiki</span>
                    {yugashiki ? 
                        <ExpandLess />
                        :
                        <ExpandMore />    
                    }
                </Button>
            </div>
            <div className='logo'>
                <Button LinkComponent={Link} to="/" className='btn icon-btn radius-full'>
                    <Logo />
                </Button>
            </div>
            <div className="navigation">
                <nav>
                    <Link to="/about">¿Quienes somos?</Link>
                </nav>
            </div>
            <div className="options">
                <Button className='btn icon-btn'>
                    <Apps />
                </Button>
                <Button className='btn icon-btn'>
                    <Avatar 
                        className='avatar-container'
                        src={account?.photoURL}
                    />
                </Button>
            </div>
        </header>
    )
}
